<template>
<v-app id="inspire">

    <v-navigation-drawer v-model="drawer">
        <v-list-item color="grey-lighten-4" class="pa-4 text-none-select" :subtitle="AccountType" :title="AccountName">
            <template v-slot:prepend>
                <v-avatar color="red">
                    <v-icon color="white">mdi-account</v-icon>
                </v-avatar>
            </template>

        </v-list-item>

        <v-list>
            <v-list-item v-for="[icon, text, link] in filteredLinks" :key="icon" :prepend-icon="icon" :title="text" link @click="Link(link)"></v-list-item>

        </v-list>
        <v-divider></v-divider>
        <v-list density="compact" nav>
            <v-list-item prepend-icon="mdi-logout" title="Logout" value="Logout" @click="Logout"></v-list-item>
        </v-list>
    </v-navigation-drawer>

    <v-app-bar v-if="bar">
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

        <v-app-bar-title>
            {{ FuncName }}

        </v-app-bar-title>

    </v-app-bar>

    <v-main>
        <!-- {{ currentPage }} -->
        <router-view></router-view>

    </v-main>
    <v-dialog persistent v-model="SettingDialog" max-width="600">
        <v-card title="Setting">

            <v-card-text>
                <v-row dense>
                    <v-col cols="12">

                    </v-col>

                    <v-col cols="12">
                        <v-autocomplete label="Website" :items="websiteOptions" v-model="Website"></v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                        <v-autocomplete label="Month" :items="['01','02','03','04','05','06','07','08','09','10','11','12']" v-model="Month"></v-autocomplete>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text="Close" variant="plain" @click="SettingClose"></v-btn>
                <v-btn color="primary" text="Save" variant="tonal" @click="SettingSave"></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-app>
</template>

<script>
export default {
    provide: {
        API_URL: 'https://autobroswer.amoikiss.com/VueAPI/'
    },
    data() {
        return {
            drawer: null,
            bar: true,
            links: [
                ['mdi-account', 'Account', '/'],
                ['mdi-account', 'Website', '/Website'],
                ['mdi-account', 'Check', '/Check'],
            ],
            AccountName: "test",
        };
    },
    created() {

    },

    computed: {

        filteredLinks() {
            return this.links.filter(([icon, text, link]) => {
                icon,
                link
                return this.AccountType === '1' || text !== 'Admin';
            });
        },
        FuncName() {
            // 根据当前页面的路径动态确定 FuncName 的值
            const currentPagePath = this.$route.path;
            const matchedLink = this.links.find(link => link[2] === currentPagePath);
            return matchedLink ? matchedLink[1] : '';
        },

    },
    methods: {
        Link(i) {
            this.$router.push(i);
        },
    },
}
</script>
