import { createRouter, createWebHistory } from 'vue-router'
import Account from '@/views/AccountPage.vue'
import Website from '@/views/WebsitePage.vue'
import Check from '@/views/CheckPage.vue'
// import ExpenseOther from '@/views/ExpenseOther.vue'
// import McProject from '@/views/McProject.vue'
// import Login from '@/views/LoginPage.vue'
// import Admin from '@/views/AdminPage.vue'

const routes = [
    { path: '/', component: Account },
    { path: '/Website', component: Website },
    { path: '/Check', component: Check },
    // { path: '/ExpenseOther', component: ExpenseOther },
    // { path: '/Mc', component: McProject },
    // { path: '/Login', component: Login },
    // { path: '/Admin', component: Admin },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
