<template>
<v-app-bar>

    <v-app-bar-title>
        <v-text-field v-model="SearchDate" type="date" label="Date" append-inner-icon="mdi-magnify" variant="outlined" hide-details single-line @click:append-inner="initialize"></v-text-field>

    </v-app-bar-title>
    <v-app-bar-title class="pa-1 text-right">
        <v-text-field v-model="Search" label="Search" prepend-inner-icon="mdi-magnify" variant="outlined" hide-details single-line></v-text-field>
    </v-app-bar-title>
</v-app-bar>
<v-data-table :search="Search" :headers="headers" :items="desserts">
    <template v-slot:top>
        <v-toolbar flat>
            <v-toolbar-title>My Check</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>

        </v-toolbar>
    </template>

    <template v-slot:item="{ item }">
        <tr>
            <td>{{ item.Account }}</td>
            <td>{{ item.Date }}</td>
            <td>{{ item.Report }}</td>

        </tr>
    </template>

    <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Reset</v-btn>
    </template>
</v-data-table>
</template>

<script>
import axios from 'axios';
export default {
    inject: ['API_URL'],
    data: () => ({
        SearchDate: '',
        Search: '',
        headers: [{
                title: 'Account',
                key: 'Account'
            },
            {
                title: 'Date',
                key: 'Date'
            },
            {
                title: 'Report',
                key: 'Report'
            },
        ],
        desserts: [],
    }),

    created() {
        // this.initialize()
    },

    methods: {

        initialize() {
            if (this.SearchDate == '') {
                alert('Please input Date')
            } else {
                const formData = new FormData();

                formData.append('Date', this.SearchDate);
                axios.post(this.API_URL + 'CheckDessert.php', formData, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                }).then(response => {
                    this.desserts = response.data;

                });
            }

        },

    },
}
</script>
