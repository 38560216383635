<template>
    <v-text-field v-model="Search" label="Search" prepend-inner-icon="mdi-magnify" variant="outlined" hide-details
        single-line></v-text-field>
    <v-data-table :search="Search" :headers="headers" :items="desserts" :sort-by="[{ key: 'calories', order: 'asc' }]">
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>My Account</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="500px">
                    <template v-slot:activator="{ props }">
                        <v-btn class="mb-2" color="primary" dark v-bind="props">
                            New Item
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-select label="Website" :items="websiteOptions"
                                            v-model="editedItem.Website"></v-select>
                                        <!-- <v-text-field  label=""></v-text-field> -->
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field v-model="editedItem.Account" label="Account"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field v-model="editedItem.Password" label="Password"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field v-model="editedItem.Currency" label="Currency"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue-darken-1" variant="text" @click="close">
                                Cancel
                            </v-btn>
                            <v-btn color="blue-darken-1" variant="text" @click="save">
                                Save
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                        <v-card-title class="text-h5">
                            Are you sure you want to delete this item?
                        </v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue-darken-1" variant="text" @click="closeDelete">
                                Cancel
                            </v-btn>
                            <v-btn color="blue-darken-1" variant="text" @click="deleteItemConfirm">
                                OK
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>

        <template v-slot:item="{ item }">
            <tr>
                <td>{{ item.ID }}</td>
                <td>{{ item.Name }}</td>
                <td>{{ item.Account }}</td>
                <td>{{ item.Password }}</td>
                <td>{{ item.Currency }}</td>
                <td><v-switch color="primary" v-model="item.Status" @change="AccountStatus(item)"></v-switch></td>
                <td>
                    <v-icon class="me-2" size="small" @click="editItem(item)">
                        mdi-pencil
                    </v-icon>
                    <v-icon size="small" @click="deleteItem(item)">
                        mdi-delete
                    </v-icon>
                </td>
            </tr>
        </template>

        <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">Reset</v-btn>
        </template>
    </v-data-table>
</template>

<script>
import axios from 'axios';
export default {
    inject: ['API_URL'],
    data: () => ({
        Search: '',
        dialog: false,
        dialogDelete: false,
        headers: [
            {
                title: 'ID',
                key: 'ID'
            }, {
                title: 'Website',
                key: 'Name'
            },
            {
                title: 'Account',
                key: 'Account'
            },
            {
                title: 'Password',
                key: 'Password'
            }, {
                title: 'Currucy',
                key: 'Currucy'
            }, {
                title: 'Status',
                key: 'Status'
            },
            {
                title: 'Actions',
                key: 'actions',
                sortable: false
            },
        ],
        desserts: [],
        websiteID: 0,
        WebList: [],
        editedIndex: -1,
        editedWebID: 0,
        editedItem: {
            Website: '',
            Account: '',
            Password: '',
            Currency: ''
        },
        defaultItem: {
            Website: '',
            Account: '',
            Password: '',
            Currency: ''
        },
    }),

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
        },
        websiteOptions() {
            return this.WebList.map(item => item.Name);
        }
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },

    created() {
        this.initialize()
        this.CheckWebList()
    },

    methods: {
        CheckWebList() {
            axios.post(this.API_URL + 'WebsiteList.php', {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(response => {

                this.WebList = response.data;

            });

        },
        initialize() {
            axios.post(this.API_URL + 'AccountList.php', {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(response => {
                this.desserts = response.data;

            });
        },

        editItem(item) {
            this.editedWebID = item.ID;
            this.editedIndex = this.desserts.indexOf(item)
            this.editedItem = {
                Website: item.Name,
                ...item
            }
            this.dialog = true
        },

        deleteItem(item) {

            this.editedWebID = item.ID;
            // console.log(item.ID);
            // this.editedIndex = this.desserts.indexOf(item)
            // this.editedItem = {
            //     ...item
            // }
            this.dialogDelete = true
        },

        deleteItemConfirm() {

            const formData = new FormData();
            formData.append('WebID', this.editedWebID);
            axios.post(this.API_URL + 'DeleteAccount.php', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(response => {
                if (response.data.error) {
                    this.editedWebID = 0;
                }
            });
            // console.log(this.editedIndex);
            // this.desserts.splice(this.editedIndex, 1)
            this.initialize()
            this.closeDelete()
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = {
                    ...this.defaultItem
                }
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = {
                    ...this.defaultItem
                }
                this.editedIndex = -1
            })
        },
        AccountStatus(item) {
            const formData = new FormData();

            formData.append('ID', item.ID);
            formData.append('Status', (item.Status) ? "1" : "0");

            axios.post(this.API_URL + "AccountStatus.php", formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            });
        },
        save() {
            const websiteObject = this.WebList.find(item => item.Name === this.editedItem.Website);
            const WebID = websiteObject ? websiteObject.ID : '0'; // Get the ID
            let myURL = '';

            const formData = new FormData();
            formData.append('WebID', WebID);

            formData.append('Account', this.editedItem.Account);
            formData.append('Password', this.editedItem.Password);
            formData.append('Currency', this.editedItem.Currency);
            if (this.editedIndex === -1) {
                myURL = 'NewAccount.php';
            } else {
                formData.append('AccountID', this.editedWebID);
                myURL = 'EditAccount.php';
            }
            axios.post(this.API_URL + myURL, formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(response => {
                if (response.data.error) {
                    console.log(response.data.message)
                } else {
                    this.initialize()
                    this.close()
                }
            });

        },
    },
}
</script>
